import { useState} from "react";
import { useNavigate } from "react-router-dom";

const HeroSection = () => {
  // State to manage video loading
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);
  const navigate = useNavigate();

  // Handle when video is loaded
  const handleVideoLoad = () => {
    setIsVideoLoaded(true);
  };

  return (
    <section className="relative w-full h-screen overflow-hidden">
      {/* Static Image (visible until video loads) */}
      {!isVideoLoaded && (
        <img
          src="/1.png"
          alt="Hero background"
          className="absolute top-0 left-0 w-full h-full object-cover z-10"
        />
      )}

      {/* Video Background */}
      <video
        className={`absolute top-0 left-0 w-full h-full object-cover z-0 transition-opacity duration-500 ${isVideoLoaded ? "opacity-100" : "opacity-0"}`}
        autoPlay
        muted
        loop
        playsInline
        onLoadedData={handleVideoLoad}
      >
        <source src="https://d2h83f1sv5kqf0.cloudfront.net/WWR_Video_2.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>

      {/* Content on top of video/image */}
      <div className="relative z-20 flex items-center justify-center w-full h-full bg-black bg-opacity-50">
        <div className="text-center text-white">
          <h1 className="text-4xl md:text-6xl font-bold mb-4">Veteran Owned and Operated</h1>
          <p className="text-lg md:text-xl">Serving our community with the same dedication we served our country.</p>
          <button className="mt-6 px-6 py-3 bg-primary rounded-full text-white shadow hover:bg-secondary font-bold transition" onClick={()=>navigate("/contact")}>
          Get Started
          </button>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
