import { PiSolarRoofBold } from "react-icons/pi";
import { TbChristmasTree } from "react-icons/tb";
import { FaHammer } from "react-icons/fa";
import { FcInspection } from "react-icons/fc";
import { IoThunderstorm } from "react-icons/io5";
import { GrHostMaintenance } from "react-icons/gr";
import { FaRedoAlt } from "react-icons/fa";
import { IoIosBusiness } from "react-icons/io";


const servicesData = [
  {
    title: "Residential Roofing",
    description: "Your home deserves the best, and that’s what we deliver. From new roof installations to repairs and regular maintenance, we’ve got your residential roofing needs covered. We treat every home like it’s our own, ensuring you get the quality and service you deserve.",
    icon: "roof",
  },
  {
    title:"Commercial Roofing MSA",
    description: "At Wounded Warrior Roofing, we know how important your commercial property is to your success. As a veteran-owned business, we provide strong, reliable roofs that protect your business. Whether you need a new flat roof or regular maintenance for a more complex structure, we've got you covered.",
    icon:"business",
  },
  {
    title: "Roof Repair",
    description: "If your roof has a leak, missing shingles, or other issues, we’re here to help. Our experienced team will quickly assess the damage and get it fixed before it becomes a bigger problem.",
    icon: "hammer",
  },
  {
    title: "Roof Maintenance",
    description: "Regular maintenance can extend the life of your roof and save you money in the long run. We offer routine inspections and maintenance services to keep your roof in top shape, catching small issues before they turn into costly repairs.",
    icon: "maintenance",
  },
  {
    title: "Roof Inspection",
    description: "Need to know the condition of your roof? Our detailed roof inspections give you a clear picture of what’s going on up there. Whether you’re buying a new property or just want to make sure your current roof is holding up, we’ll provide a thorough assessment.",
    icon: "inspection",
  },
  // {
  //   title: "Christmas Lights",
  //   description: "Let us make your holidays shine! We offer professional Christmas light installation services, so you can enjoy a beautifully lit home or business without the hassle. We’ll handle everything—from design to takedown—so you can focus on the festive season.",
  //   icon: "christmas",
  // },
  // {
  //   title: "Storm Damage",
  //   description: "Storms can wreak havoc on your roof, but we’re ready to respond quickly. We specialize in assessing and repairing storm-related damage from high winds to hail damage. We’ll even help you navigate the insurance process to make things easier on you.",
  //   icon: "storm",
  // },
  {
    title:"Interior / Exterior Makeover",
    description: "We specialize in interior painting, drywall repair, and installation of trim and molding. Whether you want to refresh a single room or give your entire home a makeover, our skilled team will work with you to achieve your vision.",
    icon:"redo",
  }
];

const Icons = {
  "roof": PiSolarRoofBold,
  "christmas": TbChristmasTree,
  "hammer": FaHammer,
  "inspection":FcInspection,
  "storm": IoThunderstorm,
  "maintenance":GrHostMaintenance,
  "redo":FaRedoAlt,
  "business":IoIosBusiness
};

const Services = () => {
  return (
    <section className="py-12 bg-gray-100">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold text-center mb-12">Our Services</h2>
        <div className="grid gap-8 grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
          {servicesData.map((service, index) => {
            const IconComponent = Icons[service.icon];
            return (
              <div
                key={index}
                className="flex p-8 bg-white shadow-lg rounded-lg transform transition-transform duration-300 hover:scale-105 hover:text-white relative"
              >
                {IconComponent && (
                  <IconComponent className="text-5xl text-secondary mr-6 my-auto" />
                )}
                <div className="p-8 border-l-2">
                  <h3 className="text-xl font-bold mb-4">{service.title}</h3>
                  {/* Hide description text initially and show it on hover */}
                  <p className="opacity-0 hover:opacity-100 transition-opacity duration-300 absolute left-0 top-0 w-full h-full bg-primary bg-opacity-100 flex items-center justify-center text-white p-8 rounded-lg">
                    {service.description}
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default Services;
