import { useNavigate } from "react-router-dom";


const GetStarted = () => {
    const navigate = useNavigate();
  return (
    <section className="py-12 bg-gray-100 bg-[url('sky.jpg')] relative">
      <div className="container mx-auto px-4 flex flex-col lg:flex-row items-center lg:items-start min-h-[400px]">
        {/* Text Section */}
        <div className="lg:w-1/2 text-left lg:pr-8 my-auto z-50">
          <h2 className="text-3xl lg:text-5xl font-bold text-gray-800 mb-4">
            Ready to upgrade your roof?
          </h2>
          <p className="mb-6">
            Contact us today at (918) 742-8482 or fill out our Contact Form for a free, no-obligation roofing estimate. Let’s get started on protecting and enhancing your home!
          </p>
          <button className="bg-primary text-white font-semibold py-3 px-8 rounded-lg shadow-lg hover:bg-red-900 transition duration-300" onClick={()=>navigate("/contact")}>
            Request An Estimate
          </button>
        </div>

        {/* Blank flex box */}
        <div className="lg:w-1/2 flex" />
        {/* Absolute positioned image */}
        <img src="/roof.png" className="w-[800px] xl:w-[1100px] ml-auto absolute bottom-0 right-0 z-40" alt="Roof" />
      </div>
    </section>
  );
};

export default GetStarted;

