import React, { useState } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function FreeEstimate() {
  const [formData, setFormData] = useState({
    name: '',
    address: '',
    phone: '',
    email: ''
  });
  const placeholderStyles = "bg-gray-100 rounded-[2rem] pl-4 shadow mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm placeholder:text-gray-900 font-semibold";

  const encode = (data) => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&");
  }

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (type === 'checkbox') {
      if (checked) {
        setFormData((prevData) => ({
          ...prevData,
          services: [...prevData.services, value]
        }));
      } else {
        setFormData((prevData) => ({
          ...prevData,
          services: prevData.services.filter((service) => service !== value)
        }));
      }
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value
      }));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "free-estimate", ...formData })
    })
      .then(() => toast.success("Submission Received!"))
      .then(() => {
        setFormData({
          name: '',
          address: '',
          phone: '',
          email: ''
        })
      })
      .then(() => { document.getElementById('privacyPolicy').checked = false })
      .catch(error => toast.error(error));
  };

  const header = "request a free estimate today";
  const body = "At Wounded Warrior Roofing, we understand the importance of choosing a contractor you can trust. Our team of dedicated veterans bring integrity, commitment, and skill to every job-whether it's roofing, interior finishing, or exterior repairs. We're here to protect and enhance every part of your property.";

  return (
    <>
      <ToastContainer />
      <section className='w-full flex flex-col md:flex-row bg-primary justify-around py-10'>
        <div className='flex flex-col h-96 md:w-1/2 lg:w-[700px]  max-w-[700px] p-10 justify-center text-left gap-8'>
          <h1 className='text-white font-semibold text-[1.6em] lg:text-[1.8em]'>{header.toLocaleUpperCase()}</h1>
          <p className='text-white lg:text-xl'>{body}</p>
        </div>
        <form className="bg-white rounded-[2rem] p-10 h-fit w-[300px] mx-auto mb-10 md:my-auto md:mx-0 lg:w-[500px] min-w-[350px]" onSubmit={handleSubmit}>
          <input type="hidden" name="form-name" value="free-estimate" />
          {/* Name */}
          <div className="mb-3">
            {/* <label className="block text-sm font-medium text-gray-700">Name</label> */}
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              className={placeholderStyles}
              placeholder="Full Name"
              required
            />
          </div>

          {/* Address */}
          <div className="mb-3">
            {/* <label className="block text-sm font-medium text-gray-700">Address</label> */}
            <input
              type="address"
              name="address"
              value={formData.address}
              onChange={handleChange}
              className={placeholderStyles}
              placeholder="Full Address"
              required
            />
          </div>
          {/* Phone */}
          <div className="mb-3">
            {/* <label className="block text-sm font-medium text-gray-700">Phone</label> */}
            <input
              type="phone"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              className={placeholderStyles}
              placeholder="Telephone"
              required
            />
          </div>
          {/* Email */}
          <div className="mb-3">
            {/* <label className="block text-sm font-medium text-gray-700">Email</label> */}
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className={placeholderStyles}
              placeholder="Email Address"
              required
            />
          </div>
          {/* Privacy Policy */}
          <div className="mb-3 flex gap-2">
            <input
              type="checkbox"
              name="privacy-policy"
              id="privacyPolicy"
              required
            />
            <label className="block text-sm font-medium text-gray-700">I have read and agree to the <a className='underline' href='/privacy-policy'>privacy policy</a></label>
          </div>
          {/* Submit Button */}
          <div className="text-center">
            <button
              type="submit"
              className="w-full px-4 py-2 bg-primary text-white font-semibold rounded-md shadow-md hover:bg-secondary transition-all focus:outline-none focus:ring-offset-2"
            >
              REQUEST NOW
            </button>
          </div>
        </form>

      </section>
    </>
  )
}

export default FreeEstimate