import React from 'react'

export default function PrivacyPolicy() {
  return (
    <div className='font-semibold min-h-screen'>
      Lorem ipsum odor amet, consectetuer adipiscing elit. Euismod potenti erat curabitur id fermentum posuere eros. Class elit nunc bibendum cubilia nibh neque erat enim. Montes dignissim justo potenti vulputate conubia; nisi nullam hac. Porta neque erat quis commodo platea condimentum tincidunt. Tempor rhoncus penatibus velit faucibus dapibus dapibus vestibulum viverra tristique. Tellus placerat fusce nullam eros eleifend nascetur dolor sapien mauris. Tempor etiam potenti ullamcorper consectetur ex fames porta nisl. Faucibus fames magna accumsan pellentesque pharetra parturient. Arcu lacinia accumsan id finibus odio posuere penatibus massa.

Nam mi porttitor; a eu eget elementum metus sociosqu montes. Auctor torquent pellentesque tempor venenatis, auctor per congue. Ac quisque ultricies quam; vehicula libero mattis. Dictum dapibus neque nullam ad nam condimentum facilisis. Nascetur ut nibh lectus pulvinar iaculis in tempus. Elementum senectus eleifend dapibus torquent volutpat pellentesque netus natoque. Rutrum efficitur senectus rutrum mauris velit vivamus. Mi cursus class; convallis mus fringilla elit taciti. Est mi senectus turpis dictumst auctor auctor.

Egestas ad aptent litora phasellus efficitur sagittis enim pulvinar. Fames placerat morbi ut vitae aliquam hendrerit mus. Montes magna nam nisl aenean luctus ad curae accumsan molestie. Sociosqu volutpat laoreet volutpat enim posuere; a est egestas. Mollis facilisis urna vulputate; bibendum maximus cursus libero. Pulvinar mollis purus venenatis enim facilisis mattis primis. Tempus mauris eleifend congue etiam purus phasellus parturient at tortor. Dignissim duis sociosqu suspendisse velit felis at. Ahimenaeos maecenas eros ornare dapibus ultrices facilisi aenean tellus.

Ante amet habitant porttitor inceptos faucibus gravida malesuada cubilia accumsan? Dis est mauris ligula dignissim fames iaculis taciti. Sem nascetur non vulputate aenean semper commodo. Eget nisi efficitur curabitur venenatis habitant nullam purus. Curae odio nostra sapien proin risus. Nullam donec justo nascetur faucibus risus donec.

Molestie pellentesque pulvinar aliquam accumsan, mauris ac. Himenaeos nisl vivamus dapibus condimentum id urna. Nec morbi magna odio sodales diam. Hendrerit vestibulum habitant senectus curae nascetur semper molestie nascetur. Vehicula duis purus sodales mus libero amet, molestie aliquam. Condimentum non netus sodales nascetur phasellus. Lobortis cursus aenean quis feugiat morbi? Vehicula arcu ligula luctus volutpat class pellentesque eget phasellus metus.
    </div>
  )
}
