const About = () => {
  return (
    <section className="py-12 bg-gray-100">
      <div className="container mx-auto px-4">
        <div className="flex flex-col lg:flex-row justify-around items-center">

          {/* Owner's Photo on the Left */}
          <div className="w-full lg:w-[33%]">
            <img
              src="/owners_transparent.png"
              alt="Owners"
              className="w-full h-auto"
            />
          </div>

          {/* Welcome Text on the Right */}
          <div className="w-full lg:w-1/2 lg:pl-12 lg:pt-10 mt-8 lg:mt-0 ">
            <h2 className="text-4xl font-bold mb-4">Meet the Owners</h2>
            <p className="text-lg text-gray-700 mb-6">
              “Hey, we’re Levi and Jason—two proud Marine veterans and the founders of Wounded Warrior Roofing. After years of serving our country, we knew we wanted to continue making a difference. That’s why we combined our skills and experience to deliver high-quality roofing services that protect the homes and businesses of hardworking people like you.

            </p>
            <p className="text-lg text-gray-700">
              Whether it’s your home or your business, you can count on us to have your back. We know how important your property is, and we’ll make sure your roof is strong, reliable, and done right.”
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
