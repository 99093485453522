import React from 'react'

export default function Promo() {
  return (
    <section className="py-12 bg-secondary text-white">
      <div className="container mx-auto px-4 flex flex-col lg:flex-row items-center">
        {/* Video Testimonial */}
        <div className="lg:w-1/2 flex justify-center mx-auto">
          <video
            className="w-full h-auto rounded-lg shadow-lg"
            controls
          >
            <source src="https://d2h83f1sv5kqf0.cloudfront.net/wwr_promo.mp4#t=0.5" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
    </section>
  )
}
