const Testimonials = () => {
  return (
    <section className="py-12 bg-primary text-white">
      <div className="container mx-auto px-4 flex flex-col lg:flex-row items-center lg:items-start">
        {/* Text Section */}
        <div className="lg:w-1/2 text-left lg:pr-8 mb-8 lg:my-auto">
          <h2 className="text-4xl font-bold mb-4">Why Choose Us?</h2>
          <h3 className="text-xl font-semibold mb-3">We help with insurance claims.</h3>
          <p className="text-lg">
            Handling insurance and building codes can be tricky. Roof replacement often involves navigating complex insurance claims and complying with building codes. At Wounded Warrior Roofing, our experienced team is here to support you every step of the way, working closely with your insurance provider to ensure everything is properly addressed. We’ll guide you through each phase of the insurance claims process, making it easier and less stressful for you. From start to finish, we’ll ensure your roof not only looks great but also meets all local regulations and insurance standards. You can trust us to handle the details so you don’t have to.
          </p>
        </div>

        {/* Video Testimonial */}
        <div className="lg:w-1/2 flex justify-center">
          <video
            className="w-full h-auto rounded-lg shadow-lg"
            controls
          >
            <source src="https://d2h83f1sv5kqf0.cloudfront.net/WWR_Testimonial.mp4#t=0.5" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
    </section>
  );
};

export default Testimonials;

