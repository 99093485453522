import React, { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Contact = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    insurance: '',
    message: '',
    services: []
  });

  const servicesList = [
    'Residential Roofing',
    'Commercial Roofing MSA',
    'Roof Inspection',
    'Remodeling',
    'Gutters',
    'Fascia',
    'Soffit',
    'Siding',
    'Leak Detection',
    'Attic Ventilation',
    'Restoration',
    // 'Roof Repair',
    // 'Roof Installation',
    // 'Roof Maintenance',
    // 'Christmas Lights',
    // 'Storm Damage',
  ];

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (type === 'checkbox') {
      if (checked) {
        setFormData((prevData) => ({
          ...prevData,
          services: [...prevData.services, value]
        }));
      } else {
        setFormData((prevData) => ({
          ...prevData,
          services: prevData.services.filter((service) => service !== value)
        }));
      }
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value
      }));
    }
  };


  const encode = (data) => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&");
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "main-form", ...formData })
    })
      .then(() => toast.success("Submission Received!"))
      .then(() => {
        setFormData({
          firstName: '',
          lastName: '',
          phone: '',
          email: '',
          insurance: '',
          message: '',
          services: []
        })
      })
      .then(()=>{document.getElementById('privacyPolicy').checked = false})
      .catch(error => toast.error(error));

    e.preventDefault();
  };

  return (
    <>
      <ToastContainer />
      <div className="bg-[url('./wwr_truck.jpg')] w-full h-[400px] bg-center bg-cover">
        <div className="flex bg-[rgba(0,0,0,0.7)] h-full">
          <h2 className='font-semibold my-auto text-white text-5xl pl-5 xl:px-44'>Veteran Owned & Operated Roofing Company</h2>
        </div>
      </div>
      <div className='bg-gray-100 flex flex-col justify-start align-middle gap-5 py-16 px-12 md:px-44'>
        <h1 className='text-[1.6em] xl:text-[1.8em] font-semibold text-center xl:text-left'>Wounded Warrior Roofing</h1>
        <p className='xl:text-xl text-center xl:text-left'>We take pride in serving our community and protecting your home with top-quality roofing solutions. As a veteran-owned and operated company, we bring the same dedication
          and attention to detail we learned in service. When you choose Wounded Warrior Roofing, you're not just hiring roofers-you're partnering with a team that cares about your home
          as much as you do.
        </p>
      </div>
      <div className="min-h-screen flex flex-col-reverse xl:flex-row justify-between bg-gray-100 py-10 xl:px-44 gap-10">
        <div className="bg-white shadow-md mx-auto xl:mx-0 rounded-lg p-8 w-[350px] md:w-[550px] xl:w-[550px]">
          <h2 className="text-2xl font-semibold text-center mb-6">Contact Us</h2>
          <form onSubmit={handleSubmit}>
            <input type="hidden" name="form-name" value="main-form" />
            {/*First Name */}
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">First Name</label>
              <input
                type="text"
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
                className="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                placeholder="Your first name"
                required
              />
            </div>
            {/* Last Name */}
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">Last Name</label>
              <input
                type="text"
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
                className="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                placeholder="Your last name"
                required
              />
            </div>
            {/* Phone */}
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">Phone</label>
              <input
                type="text"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                className="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                placeholder="Your phone number"
                required
              />
            </div>

            {/* Email */}
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">Email</label>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                className="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                placeholder="Your email"
                required
              />
            </div>

            {/* Insurance */}
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">Insurance</label>
              <input
                type="insurance"
                name="insurance"
                value={formData.insurance}
                onChange={handleChange}
                className="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                placeholder="Current Insurance Company"
                required
              />
            </div>

            {/* Message */}
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">Message</label>
              <textarea
                name="message"
                value={formData.message}
                onChange={handleChange}
                className="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                placeholder="Your message"
                rows="4"
              />
            </div>

            {/* Services */}
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700 mb-2">Interested in Services</label>
              {servicesList.map((service) => (
                <div key={service} className="flex items-center mb-2">
                  <input
                    type="checkbox"
                    name="services"
                    value={service}
                    checked={formData.services.includes(service)}
                    onChange={handleChange}
                    className="h-4 w-4 text-indigo-600 border-gray-300 rounded"
                  />
                  <label className="ml-2 text-sm text-gray-700">{service}</label>
                </div>
              ))}
            </div>
            {/* Privacy Policy */}
            <div className="mb-3">
              <label className="block text-sm font-medium text-gray-700 mb-2">Privacy Policy</label>
              <div className='flex gap-2'>
                <input
                  type="checkbox"
                  name="privacy-policy"
                  id="privacyPolicy"
                  required
                />
                <label className="block text-sm text-gray-700">I have read and agree to the <a className='underline' href='/privacy-policy'>privacy policy</a></label>
              </div>
            </div>

            {/* Submit Button */}
            <div className="text-center">
              <button
                type="submit"
                className="w-full px-4 py-2 bg-primary text-white font-semibold rounded-md shadow-md hover:bg-secondary transition-all focus:outline-none focus:ring-offset-2"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
        <div className="px-4 flex flex-col xl:w-[600px] gap-10 mx-auto xl:mx-0">
          <div className='xl:hidden flex justify-center'>
            <iframe title="WWR Map" width="350" height="400" style={{ border: "0" }} loading="lazy" allowfullscreen src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJYcDHGV6PtocRRZ5vMJKosaI&key=AIzaSyC-LmalrgHyp6BP7NsET4oeCwCFzaAhrXg"></iframe>
          </div>
          <div className='hidden xl:inline-block'>
            <iframe title="WWR Map" width="600" height="450" style={{ border: "0" }} loading="lazy" allowfullscreen src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJYcDHGV6PtocRRZ5vMJKosaI&key=AIzaSyC-LmalrgHyp6BP7NsET4oeCwCFzaAhrXg"></iframe>
          </div>
          <img src="/WoundedLogo.png" alt="logo" className="w-64 xl:w-96 mx-auto pt-10" />
          {/* Text Section */}
          <div className="text-center mb-8 mx-auto xl:mx-0 xl:mb-0 max-w-md xl:max-w-none">
            <h2 className="text-3xl xl:text-4xl font-bold mb-4">Request a free estimate today!</h2>
            <p className="xl:text-lg">
              At Wounded Warrior Roofing, we understand the importance of choosing a roofing contractor you can trust. Our team is made up of dedicated veterans who bring a strong sense of integrity, commitment, and skill to every project.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
