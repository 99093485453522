import React from 'react'
import HeroSection from '../components/home/hero'
import Services from '../components/home/services'
import About from '../components/home/about'
import Testimonials from '../components/home/testimonial'
import GetStarted from '../components/home/get-started'
import FreeEstimate from '../components/home/free-estimate'
import Promo from '../components/home/promo'

function Home() {
  return (
    <>
      <HeroSection />
      <FreeEstimate />
      <About />
      <Promo />
      <Services />
      <Testimonials />
      <GetStarted />
    </>
  )
}

export default Home